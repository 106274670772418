import Vue from 'vue'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper)

import "./assets/js/rem.js"
import "./assets/css/reset.css"
import { Dialog, Toast} from 'vant';
import App from './App.vue'
import router from "./router";
import store from "./store";
import http from "./api/api.js";
import $public from "./util/public.js";
import $dict from "./util/dict.js";

Vue.config.productionTip = false
Vue.use(Dialog);
Vue.use(Toast);
Vue.prototype.$http=http;
Vue.prototype.$public=$public;
Vue.prototype.$dict=$dict;
new Vue({
  render: h => h(App),
  router,store,
}).$mount('#app')
