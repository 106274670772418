const aaa = [
	{
		path: "goodsDetail",
		component: () => import("@/views/hsPet/goodsDetail.vue"),
		meta:{keepAlive:false},
	},
	{
		path: "contactUs",
		component: () => import("@/views/hsPet/contactUs.vue"),
		meta:{keepAlive:true},
	},
	{
		path: "orderMsg",
		component: () => import("@/views/hsPet/orderMsg.vue"),
		meta:{keepAlive:true},
	},
	{
		path: "payResult",
		component: () => import("@/views/hsPet/payResult.vue"),
		meta:{keepAlive:true},
	},
	{
		path: "imagePage",
		component: () => import("@/views/hsPet/imagePage.vue"),
		meta:{keepAlive:false},
	},
	{
		path: "swiperPage",
		component: () => import("@/views/hsPet/swiperPage.vue"),
		meta:{keepAlive:false},
	},
]
export default aaa;