import Vue from "vue";
import createPersistedState from 'vuex-persistedstate';

// import guahao from './children/guahao.js'
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
	// modules:{guahao},
	state: {
		shopId:'',
		userInfo:'',
		
		curIndex:'ximei',
		unread:{
			ximei:0,
			jiyang:0,
			huoti:0,
		},
	},
	getters: {
		
	},
	mutations: {
		setShopId(state, obj) {
			state.shopId = obj;
		},
		setUserInfo(state, payload) {
			state.userInfo = payload;
		},
		setCurIndex(state,obj){
			state.curIndex = obj;
		},
		setUnread(state,obj){
			state.unread = obj;
		}
	},
	actions: {

	},
	plugins: [createPersistedState({
		storage: window.sessionStorage,
		reducer(val) {
			return {
				shopId:val.shopId,
				userInfo: val.userInfo,
				curIndex: val.curIndex,
				// guahao:{
				// 	type:val.guahao.type,
				// },
			}
		}
	})]
});
