const moment = require('moment')
const pub = {
	
	formatOrderState(state){
		let result = '';
		if(state===0){result = '已删除'}
		else if(state===1){result = '已创建'}
		else if(state===2){result = '支付中'}
		else if(state===3){result = '支付成功'}
		else if(state===4){result = '已接单'}
		else if(state===5){result = '已发货'}
		else if(state===8){result = '已完成'}
		else if(state===-2){result = '已取消'}
		else if(state===-3){result = '退换中'}
		else if(state===-4){result = '退换成功'}
		else if(state===11){result = '待付款'}
		else if(state===12){result = '待发货'}
		else if(state===13){result = '待收货'}
		else if(state===14){result = '待评价'}
		else if(state===15){result = '退货'}
		else{result = '未知'}
		return result;
	},
	formatOrderReturnState(state){
		let result = '';
		if(state===0){result = '已删除'}
		else if(state===1){result = '已申请'}
		else if(state===2){result = '已审核'}
		else if(state===3){result = '退换中'}
		else if(state===4){result = '物流中'}
		else if(state===8){result = '已完成'}
		else if(state===-2){result = '已取消'}
		else{result = '未知'}
		return result;
	},
	formatPayTypeState(state){
		let result = '';
		if(state===0){result = '现金'}
		else if(state===1){result = '线上支付宝'}
		else if(state===2){result = '线上微信'}
		else if(state===3){result = '线下支付宝'}
		else if(state===4){result = '线下微信'}
		else if(state===5){result = 'pos'}
		else if(state===6){result = '找零'}
		else if(state===7){result = '余额'}
		else if(state===8){result = '赠额'}
		else if(state===9){result = '积分'}
		else if(state===10){result = '押金'}
		else if(state===11){result = '第三方'}
		else if(state===12){result = '赊账'}
		else if(state===13){result = '京东聚合'}
		else{result = '未知'}
		return result;
	},
	// 获取 url 地址
	queryString(qs){
		let s = location.href;
		s = s.replace("?","?&").split("&");
		let re="";
		for(let i=1;i<s.length;i++){
			if(s[i].indexOf(qs+'=')==0){
				re=s[i].replace(qs+"=","");
			}
		}
		return re;
	},
	// 计算小计
	getSubtotal(row){
		let subtotal = 0
		if(row.allow_wholesale===0){
			if(row.dosageCeiling===1){
				if(row.dosageCeilingValue!==0){
					row.countPriceDosage = 
					this.cheng(
						(
							parseInt(row.goodsDosage/row.dosageCeilingValue)+
							(this.quyu(row.goodsDosage,row.dosageCeilingValue)===0?0:1)
						),
						row.dosageCeilingValue
					)
				}else{
					row.countPriceDosage=row.goodsDosage
				}
			}else{
				row.countPriceDosage=row.goodsDosage
			}
			subtotal = this.cheng(row.price,row.countPriceDosage)
		}else{
			subtotal = this.cheng(row.price,row.num)
		}
		return subtotal
	},
	// 补全条码
	completeScanningCode(str){
		let len = str.length;
		if(len==12){
			return str;
		}else if(len>12){
			alert('条码输入有误')
		}else{
			let result = '1';
			for(let i=0;i<11-len;i++){
				result = result + '0'
			}
			return result+str;
		}
	},
	// 获取当前闷蛋
	getShopNameById(shopId){
		let shopName = ''
		if(shopId==='5e9412e0b2bfbc089daerr12'){
			shopName="北环"
		}else if(shopId==='5e9412e0b2bfbc089daerr13'){
			shopName="陇海"
		}else if(shopId==='5e9412e0b2bfbc089daerr14'){
			shopName="指挥中心"
		}else if(shopId==='5e9412e0b2bfbc089daerr15'){
			shopName="东区"
		}else if(shopId==='5e9412e0b2bfbc089daerr16'){
			shopName="二七"
		}else if(shopId==='5e9412e0b2bfbc089daerr17'){
			shopName="线上旗舰"
		}else if(shopId==='5e9412e0b2bfbc089daerr10'){
			shopName="驯养中心"
		}else if(shopId==='5e9412e0b2bfbc089daerr11'){
			shopName="小程序"
		}
		return shopName;
	},
	calcActivity(activityItem,price,num){
		if(activityItem.consume_type===1){ //满减
			let redu = activityItem.full_reduction;
			if(redu.full_reduction_type===1){ // 按金额
				if(redu.full_reduction_conditions<=price){
					let newPrice = this.jian(price,redu.full_reduction_value);
					return {
						state:true,
						type:'byMoney',
						limit:redu.full_reduction_conditions,
						gift:{},
						newPrice:newPrice<0?0:newPrice
					}
				}else{
					return {
						state:false,
						type:'byMoney',
						limit:redu.full_reduction_conditions,
						gift:{},
						newPrice:price
					}
				}
			}else{ // 按数量
				if(redu.full_reduction_conditions<=num){
					let newPrice = this.jian(price,redu.full_reduction_value);
					return {
						state:true,
						type:'byNumber',
						limit:redu.full_reduction_conditions,
						gift:{},
						newPrice:newPrice<0?0:newPrice
					}
				}else{
					return {
						state:false,
						type:'byNumber',
						limit:redu.full_reduction_conditions,
						gift:{},
						newPrice:price
					}
				}
			}
		}else if(activityItem.consume_type===2){ //满赠
			let redu = activityItem.full_give;
			if(redu.full_give_type===1){ // 按金额
				if(redu.full_give_conditions<=price){
					return {
						state:true,
						type:'byMoney',
						limit:redu.full_give_conditions,
						gift:activityItem.give_goods,
						newPrice:price
					}
				}else{
					return {
						state:false,
						type:'byMoney',
						limit:redu.full_give_conditions,
						gift:activityItem.give_goods,
						newPrice:price
					}
				}
			}else{ // 按数量
				if(redu.full_give_conditions<=num){
					return {
						state:true,
						type:'byNumber',
						limit:redu.full_give_conditions,
						gift:activityItem.give_goods,
						newPrice:price
					}
				}else{
					return {
						state:false,
						type:'byNumber',
						limit:redu.full_give_conditions,
						gift:activityItem.give_goods,
						newPrice:price
					}
				}
			}
		}else if(activityItem.consume_type===3){ //折扣
			let newPrice = this.cheng(price,activityItem.other_info.other_info_value);
			return {
				state:true,
				type:'none',
				limit:0,
				gift:{},
				newPrice:newPrice
			}
		}else if(activityItem.consume_type===4){ //立减
			let newPrice = this.jian(price,activityItem.other_info.other_info_value);
			return {
				state:true,
				type:'none',
				limit:0,
				gift:{},
				newPrice:this.cheng(newPrice,num)
			}
		}else if(activityItem.consume_type===5){ //定价
			let newPrice = activityItem.other_info.other_info_value;
			return {
				state:true,
				type:'none',
				limit:0,
				gift:{},
				newPrice:this.cheng(newPrice,num)
			}
		}
	},
	calcAfterCoupon(totalMoney,curDiscountCard){
		if(curDiscountCard.activity_type===1){ // 满减
			totalMoney = this.jian(totalMoney,curDiscountCard.money_reduce);
		}else if(curDiscountCard.activity_type===2){ // 折扣
			let yh = this.cheng(totalMoney,this.jian(1,curDiscountCard.rebate));
			if(curDiscountCard.rebate_limit>0){
				if(yh>curDiscountCard.rebate_limit){
					yh=curDiscountCard.rebate_limit
				}
			}
			totalMoney = this.jian(totalMoney,yh);
		}
		return totalMoney;
	},
	formatNumTochinese(s_){
		if(this.isNull(s_)){
			return '--'
		}
		var _arrayCHNNum=['〇','一','二','三','四','五','六','七','八','九'];
		var _arrayCHNBit=['','十','百','千'];
		let _numArray=s_.toString().split('');
		if(_numArray.length===2&&_numArray[0]==='1'){
			_numArray[0]='y';
		}
		for(let i=_numArray.length-1;i>=0;i--){
			if(_numArray[i]==='0') {
				_numArray[i]='x';
			}  else{
				break;
			}
		}
		let _s='';
		let _bLast0=false;
		for(let i=0;i<_numArray.length;i++){
			if(_numArray[i]==='x'){
				continue;
			}
			if(_numArray[i]==='0'&&_bLast0){
				_bLast0=true;
				continue;
			}
			if(_numArray[i]!=='y'){//delete 1 in ten
				_s+=_arrayCHNNum[parseInt(_numArray[i])];
			}
			if(_numArray[i]==='0'){
				_bLast0=true;
				continue;
			}
			_bLast0=false;
			_s+=_arrayCHNBit[_numArray.length-i-1];
		}
		return _s;
	},
	getDiscountByGrades(grades){
		if(grades===0){
			return ''
		}else if(grades===1){
			return '95'
		}else if(grades===2){
			return '9'
		}else if(grades===3){
			return '88'
		}else if(grades===4){
			return '85'
		}else if(grades===5){
			return '8'
		}else{
			return ''
		}
	},
	base:'/yiliao/#',
	jia(){
		let re = 0;
		for(let i=0;i<arguments.length;i++){
			re = re + Number(arguments[i])
		}
		return this.toDoubleFixed2(re)
	},
	jian(num1,num2){
		let re = num1-num2
		return this.toDoubleFixed2(re)
	},
	cheng(num1,num2){
		let re = num1*num2
		return this.toDoubleFixed2(re)
	},
	chu(num1,num2){
		let re = Number(num1)/Number(num2)
		return this.toDoubleFixed2(re)
	},
	quyu(num1,num2){
		let re = parseInt(Number(num1)*100)%parseInt(Number(num2)*100)
		return parseInt(re)
	},
	// 获取对象某一项（arr,key,keyvalue）
	getMatchingItem(arr,key,keyValue){
		for(let i=0;i<arr.length;i++){
			if(arr[i][key]==keyValue){
				return arr[i]
			}
		}
		return {};
	},
	replaceGoodsDosage(value){
		value = String(value)
		//先把非数字的都替换掉，除了数字和.
		value = value.replace(/[^\d.]/g,""); 
		//必须保证第一个为数字而不是. 
		value = value.replace(/^\./g,""); 
		//保证只有出现一个.而没有多个. 
		value = value.replace(/\.{2,}/g,"."); 
		//保证.只出现一次，而不能出现两次以上 
		value = value.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
		console.log(value)
		return value;
	},
	FInt(value){
		value = String(value)
		value = value.replace(/[^\d]/g,"");
		return value;
	},
	// 格式化时间（时间戳,'YYYY-MM-DD HH:mm:ss'）
	FTime(str, str2){
		str = parseInt(str)
		if(!str) return '';
		if (str.toString().length == 10 || str.toString().length == 9) {
			str = parseInt(str + '000')
		}
		if (!str2) {
			str2 = 'YYYY-MM-DD HH:mm:ss';
		}
		let date = new moment(parseInt(str));
		let formatDate = date.format(str2);
		return formatDate;
	},
	// 年龄计算 (时间戳，时间戳)
	FAge(str,n2=new Date().getTime()){
		if(!str) return '';
		let d;
		if(str.length){
			if(parseInt(str)<10000){
				d = new Date(str).getTime();
			}else{
				d = new Date(parseInt(str)).getTime();
			}
		}else{
			d = new Date(str).getTime();
		}
		let n1 = d.toString().length<11?d*1000:d;
		n2 = n2.toString().length<11?n2*1000:n2;
		// 年月日时分秒
		return [new moment.duration(n2-n1).years(),new moment.duration(n2-n1).months(),new moment.duration(n2-n1).days(),new moment.duration(n2-n1).hours(),new moment.duration(n2-n1).minutes(),new moment.duration(n2-n1).seconds()];
	},
	// 获取间隔天数
	getIntervalDay(str,n2=new Date().getTime()){
		if(!str) return 0;
		let d;
		if(str.toString().length<11){
			d = new Date(str*1000)
		}else{
			d = new Date(str*1)
		}
		d = new Date(d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate()).getTime()
		let n1 = d.toString().length<11?d*1000:d;
		n2 = n2.toString().length<11?n2*1000:n2;
		return parseInt(new moment.duration(n2-n1).asDays());
	},
	// 格式化宠物性别
	FSex(a){
		if(a[1]===1){
			if(a[0]=="公"){
				return "绝育公"
			}else if(a[0]=="母"){
				return "绝育母"
			}
		}else if(a[1]===0){
			if(a[0]=="公"){
				return "公"
			}else if(a[0]=="母"){
				return "母"
			}
		}
	},
	// 出生日期计算 (str 月数)
	FBirthday(p){
		let m = new moment();
		return m.subtract(Number(p), 'months').format("YYYY-MM-DD");
	},
	// 手机号 
	isTel(tel){
		if(!(/^1[3456789]\d{9}$/.test(tel))){ 
			return false;
		}else{
			return true;
		}
	},
	// 验证邮箱
	isMail(mail) {
		var filter = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
		if (filter.test(mail)){
			return true;
		}else {
			return false;
		}
	},
	// 保留两位小数
	toDoubleFixed2(num){
		let num1 = Number((num*100).toFixed(2));
		if(num>0){
			return Math.ceil(num1)/100
		}else{
			return 0;
		}
	},
	// 是否为空
	isNull(str){
		if(str===''||str===null||str===undefined||Array.isArray(str)&&str.length===0||str==={}){
			return true;
		}else{
			return false;
		}
	},
	// 根据时间获取10位时间戳
	FTimeStr10(date){
		if(this.isNull(date)){
			return ''
		}else{
			let str = (new Date(date).getTime()).toString()
			return str.substr(0,str.length-3)
		}
	},
	sumGoodsCount(array) {//合计相同商品 和规格下得条码
					var sumGoodsData = [];
					array.forEach(item => {
						let isDiffIndex = sumGoodsData.findIndex(subitem => {
							return item.goodsId == subitem.goodsId && item.skus == subitem.skus
						})
						// 若不相同商品和规格 children 包含商品条码 sumGoodsDataitem包含相同得商品信息  
						if (isDiffIndex == -1) {
							sumGoodsData.push({
								goodsId: item.goodsId,
								skus: item.skus,
								goodsName: item.goodsName,
								skuValue: item.skuValue,
								unit: item.unit,
								count: 1,
								children: [item]
							})
						} else {
							sumGoodsData[isDiffIndex].count += 1;
							sumGoodsData[isDiffIndex].children.push(item)
							sortCustom(sumGoodsData[isDiffIndex].children)
						}
					})
					return sumGoodsData;
				},
				
}
function sortCustom(array){
					return array.sort(function(a,b){return a.customCode-b.customCode});
				}
export default pub;
