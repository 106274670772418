
export default {
	// 印章类型
	signature_type:[
		{id:1,name:'公章'},
		{id:2,name:'合同章'},
		{id:3,name:'法人章'},
		{id:4,name:'财务章'},
		{id:5,name:'发票章'},
		{id:6,name:'其他用章'},
	],
	
}