import Vue from "vue";
import VueRouter from "vue-router";
import hsPet from "./children/hsPet.js"
Vue.use(VueRouter);
const router = new VueRouter({
	routes: [
		{
			path: "/",
			redirect:"login",
			component: () => import("@/App.vue"),
		},
		{
			path: "/hsPet",
			redirect:"/hsPet/goodsDetail",
			component: () => import("@/views/frame/layout.vue"),
			children:hsPet,
		},
	]
});
export default router;
