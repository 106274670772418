import axios from 'axios';
// import store from '../store/index.js';
axios.defaults.timeout = 500000;
import vue from 'vue'
axios.defaults.baseURL = process.env.VUE_APP_apiHost

axios.interceptors.request.use(function(config) {
	config.headers.company_id = ''; // 公司id
	config.headers.shop_id = '';
	config.headers['Content-Type'] = 'application/json;';
	config.headers.platform = 'm'; // platform
	config.headers.user_ip = ''; // ip地址
	config.headers.version = '';
	config.headers.token = '';
	return config;
}, function(error) {
	return Promise.reject(error);
});
axios.interceptors.response.use(function(response) {
	if (response.data) {
		if(response.data.code!=undefined){
			switch (response.data.code) {
				case -1:
					vue.prototype.$toast({
						message: response.data.info,
						icon: 'success',
					});
					break;
				case 0:
					return response;
				case 1:
					return response;
				case 400:
					vue.prototype.$toast({
						message: response.data.info,
						icon: 'success',
					});
					break;
				case 401:
					vue.prototype.$toast({
						message: response.data.info,
						icon: 'success',
					});
					break;
				case 500:
					vue.prototype.$toast({
						message: '网络异常，请稍后重试',
						icon: 'success',
					});
					break;
				default:
					vue.prototype.$toast({
						message: response.data.info,
						icon: 'success',
					});
					break;
			}
		}else{
			return response;
		}
	}
	return response;
}, function(error) {
	return Promise.reject(error);
});
export default {
	self:axios,
	get(url, params = {}) {
		return new Promise((resolve, reject) => {
			axios.get(url, {
				params
			}).then(response => {
				let data;
				data = response.data
				resolve(data);
			}).catch(err => {
				reject(err)
			})
		})
	},
	post(url, params = {}) {
		return new Promise((resolve, reject) => {
			axios.post(url,params).then(response => {
				let data;
				data = response.data
				resolve(data);
			}).catch(err => {
				reject(err)
			})
		})
	},
	delete(url, params = {}) {
		return new Promise((resolve, reject) => {
			axios.delete(url, {
				params
			}).then(response => {
				let data;
				data = response.data
				resolve(data);
			}).catch(err => {
				reject(err)
			})
		})
	},
}
