<template>
  <div id="app">
	<transition v-if="overlay_state===false" name="slide-left">
		<router-view></router-view>
	</transition>
	
	<overlay :show="overlay_state">
		<div style="text-align: center;">
			<div style="display: inline-block;background-color: #fff;padding:.5rem 1rem;border-radius: .05rem;margin-top: 2rem;">请使用移动设备打开</div>
		</div>
	</overlay>
  </div>
</template>

<script>
import { Overlay } from 'vant';
export default {
	components:{Overlay},
	name: 'App',
	data(){
		return {
			overlay_state:false,
			// ws:null,
		}
	},
	computed:{
		userInfo(){
			return this.$store.state.userInfo;
		},
		shopId(){
			return this.$store.state.shopId;
		},
		unread(){
			return this.$store.state.unread;
		}
	},
	mounted(){
		this.$store.commit("setCurIndex",'ximei')
		if(!this.isMobile()){
			this.overlay_state = true;
			return 
		}
		// if(this.queryString('AccessToken')){
		// 	let params = {
		// 		"AccessToken": this.queryString('AccessToken'),
		// 		"systemId": this.queryString('systemId'),
		// 	}
		// 	this.$http.get(process.env.VUE_APP_token, params).then(response => {
		// 		let res = response;
		// 		if(res.code===0){
		// 			this.$store.commit('setUserInfo',res.data)
		// 			this.$store.commit('setShopId',this.queryString('shopId'))
		// 			// sessionStorage.setItem("systemId",this.queryString('systemId'))
		// 			sessionStorage.setItem("systemId","5ed3645a8304352984588f1a")
					
		// 			this.openSocket();
					
		// 			this.$router.replace({path:'/ximei/list'})
		// 		}
		// 	})
		// }else{
		// 	this.openSocket();
		// }
	},
	methods:{
		// openSocket(){
		// 	let systemId = sessionStorage.getItem('systemId');  // 系统id 在登录时写死了
		// 	let companyId = this.userInfo.companyId
		// 	let shopId = this.shopId;
		// 	if(!systemId){
		// 		return ;
		// 	}
		// 	if(!shopId){
		// 		return ;
		// 	}
		// 	var adminId = "6018a658273bcd62c1039301"
		// 	var adminName = "超级管理员"
		// 	let str = 'companyId='+companyId
		// 	+'&systemId='+systemId
		// 	+'&shopId='+shopId
		// 	+'&userId='+this.userInfo.staffId
		// 	+'&userName='+this.userInfo.uName
		// 	+'&receiverId='+adminId
		// 	+'&receiverName='+adminName
		// 	let url = process.env.VUE_APP_Url_ws+'/api/ws/connect?'+str;
		// 	let that = this;
		// 	this.ws = new WebSocket(url);
		// 	this.ws.onopen = (evt) => {
		// 		console.log('Connection open ...',evt);
		// 	};
		// 	this.ws.onmessage = (evt) => {
		// 		let a = that.unread;
		// 		let d = eval('('+evt.data+')')
		// 		let obj = JSON.parse(JSON.parse(d.content).data)
		// 		console.log(obj)
		// 		if(obj.type==='2'){
		// 			a.ximei = a.ximei+Number(obj.count)
		// 		}
		// 		else if(obj.type==='6'){
		// 			a.jiyang = a.jiyang+Number(obj.count)
		// 		}
		// 		else if(obj.type==='8'){
		// 			a.huoti = a.huoti+Number(obj.count)
		// 		}
		// 		that.$store.commit("setUnread",a)
		// 	};
		// 	this.ws.onclose = (evt) => {
		// 		console.log('Connection closed.',evt);
		// 	};
		// 	this.ws.onclose = () => {
		// 		console.log("ws close");
		// 		this.ws = null
		// 		setTimeout(()=>{
		// 			this.openSocket()
		// 		},10000)
		// 	}
		// 	this.ws.onerror = () => {
		// 		console.log('ws error');
		// 	}
		// 	// this.ws.send(JSON.stringify({ action: 'send_to_room', msg: message, nick: nick, }));
		// },
		// queryString(qs){
		// 	let s = location.href;
		// 	s = s.replace("?","?&").split("&");
		// 	let re="";
		// 	for(let i=1;i<s.length;i++){
		// 		if(s[i].indexOf(qs+'=')==0){
		// 			re=s[i].replace(qs+"=","");
		// 		}
		// 	}
		// 	console.log(re)
		// 	return re;
		// },
		isMobile() {
			var userAgentInfo = navigator.userAgent;
			var mobileAgents = [ "Android", "iPhone", "SymbianOS", "Windows Phone", "iPad","iPod"];
			var mobile_flag = false;
			//根据userAgent判断是否是手机
			for (var v = 0; v < mobileAgents.length; v++) {
				if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
					mobile_flag = true;
					break;
				}
			}
			var screen_width = window.screen.width;
			var screen_height = window.screen.height;   
			//根据屏幕分辨率判断是否是手机
			if(screen_width < 500 && screen_height < 800){
				mobile_flag = true;
			}
			return mobile_flag;
		},
	}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: .12rem;
  width:100%;
  height: 100%;
}
</style>
